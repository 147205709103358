import { useState } from "react";
import close from "../../images/close.svg";
import loading from "../../images/loading.svg";
import { Button, Box, Checkbox } from "@mui/material";
import axios from "axios";

const ViewCartPopup = ({ onClose, template, data, setActiveStep, setData, onCreateAnotherDesign, openAttentionPopup }) => {
  const [orderLoading, setOrderLoading] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  async function checkout(dataList) {
    const token = localStorage.getItem("token");
    axios({
      url: process.env.REACT_APP_API_URL || "https://labelmaker.traceaudioserver.com" + "/api/v1/shopifyAddCart",
      method: "post",
      data: dataList,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        const data = response.data;
        window.location.href = data;
        setOrderLoading(false);
      })
      .catch((error) => {
        setOrderLoading(false);
        console.error("Error:", error);
      });
  }

  function addOrder() {
    setOrderLoading(true);
    const orderData = {
      DesignName: template.name,
      DesignId: template.id,
      DesignLogotype: data[0].logotype || null,
      DesignData: [
        {
          data: data[0].data.map((item) => {
            return item;
          }),
          groups: data[0].groups,
          settings: data[0].settings,
        },
      ],
      DesignDescription: template.DesignDescription,
      Amount: data[0].amount,
      Status: 1,
      Id: data[0].Id !== undefined ? data[0].Id : null,
    };

    const dataForm = new FormData();
    if (data[0]?.logotype?.indexOf("http") === -1) {
      data[0]?.logotype && dataForm.append("file", data[0].logotype);
    }

    dataForm.append("data", JSON.stringify(orderData));

    const token = localStorage.getItem("token");

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_API_URL || "https://labelmaker.traceaudioserver.com" + "/api/v1/addOrder",
      data: dataForm,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.orderId) {
          checkout([response.data.orderId]);
          // setOrderDataResponse(response.data.orderId)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function updateOrder() {
    setOrderLoading(true);
    const orderData = {
      DesignName: template.name,
      DesignId: template.id,
      DesignLogotype: data[0].logotype || null,
      DesignData: [
        {
          data: data[0].data.map((item) => {
            return item;
          }),
          groups: data[0].groups,
          settings: data[0].settings,
        },
      ],
      DesignDescription: template.DesignDescription,
      Amount: data[0].amount,
      Status: 1,
      Id: data[0].Id !== undefined ? data[0].Id : null,
    };

    const dataForm = new FormData();
    if (!data[0].logotype.includes("http")) {
      dataForm.append("file", data[0].logotype);
    }
    dataForm.append("data", JSON.stringify(orderData));
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_API_URL || "https://labelmaker.traceaudioserver.com" + "/api/v1/editOrder",
      data: dataForm,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data === "Design updated successfully") {
          checkout([data[0].Id]);
          // setOrderDataResponse(response.data.orderId)
          setOrderLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setOrderLoading(false);
      });
  }

  function orderWithoutToken() {
    setOrderLoading(true);
    const orderData = {
      DesignName: template.name,
      DesignId: template.id,
      DesignLogotype: data[0].logotype || null,
      DesignData: [
        {
          data: data[0].data.map((item) => {
            return item;
          }),
          groups: data[0].groups,
          settings: data[0].settings,
        },
      ],
      DesignDescription: template.DesignDescription,
      Amount: data[0].amount,
      Status: 1,
      Id: data[0].Id !== undefined ? data[0].Id : null,
    };

    const dataForm = new FormData();
    if (data[0].logotype.indexOf("http") === -1) {
      dataForm.append("file", data[0].logotype);
    }
    dataForm.append("data", JSON.stringify(orderData));

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_API_URL || "https://labelmaker.traceaudioserver.com" + "/api/v1/addOrder",
      data: dataForm,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        const items = [response.data.orderId];
        sessionStorage.setItem("localeItems", JSON.stringify(items));
        checkout(items);
        // setOrderDataResponse(response.data.orderId)
      })
      .catch((error) => {
        console.log(error);
        setOrderLoading(false);
      });
  }

  const goToCart = () => {
    // openAttentionPopup()
    return data[0].isEditFromCart === true
      ? updateOrder()
      : window.localStorage.getItem("token") !== null
      ? addOrder()
      : orderWithoutToken();
  };

  // const redirectToCart = () => {
  //   window.open('https://traceaudio.com/cart', '', 'noreferrer')
  // }

  return (
    <Box className="popup">
      <Box className="popupBlock">
        {/* {true
          ? ( */}
        <>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "40px",
            }}
          >
            <p className="logoTitle" style={{ marginBlockStart: "0", marginBlockEnd: "0" }}>
              Confirmation of Proof
            </p>
            <Box className="popupClose popupLogoClose">
              <img src={close} alt="Close" onClick={onClose} />
            </Box>
          </Box>
          <Box className="popupContent popupLogoContent">
            <Box
              style={{
                display: "flex",
                alignItems: "flex-start",
                paddingTop: "12px",
              }}
            >
              <p className="logoTitle">
                By adding this design to the cart, you agree to the following: <br />
                The design shown on the preview page will act as a design proof. The label will be printed exactly as shown on the proof.
                Please carefully check the proof for any spelling or design errors and correct if applicable. Trace Audio will not be held
                responsible for spelling or design errors present in the design proof. These are print-on-demand products and are not able
                to be returned, refunded, or exchanged.
              </p>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
              <Checkbox
                value={isCheckboxChecked}
                sx={{ color: "#FFFFFF" }}
                onChange={(event) => {
                  setIsCheckboxChecked(event.target.checked);
                }}
              />
              <p style={{ marginLeft: "6px" }} className="logoTitle">
                I agree and approve the proof.
              </p>
            </Box>
          </Box>
          <Box className="popupBottomButton logoSelectButtons">
            <Button
              onClick={onClose}
              sx={{
                borderRadius: "14px",
                borderColor: "#FFFFFF",
                borderStyle: "solid",
                borderWidth: "1px",
                color: "#FFFFFF",
                "&:disabled": {
                  color: "#FFFFFF",
                  opacity: 0.4,
                  borderColor: "#FFFFFF",
                  borderWidth: "1px",
                },
              }}
              variant={"outlined"}
              disabled={orderLoading}
            >
              Go back
            </Button>
            <Button
              sx={{
                borderRadius: "14px",
                borderColor: "#FFFFFF",
                borderStyle: "solid",
                borderWidth: "1px",
                "&:disabled": {
                  color: "#FFFFFF",
                  opacity: 0.4,
                  borderColor: "#FFFFFF",
                  borderWidth: "2px",
                },
              }}
              variant={!isCheckboxChecked || orderLoading ? "outlined" : "contained"}
              disabled={!isCheckboxChecked || orderLoading}
              onClick={goToCart}
            >
              {data[0].isEditFromCart === true ? "Update Cart" : "Add to Cart"}
              {orderLoading && <img className="loading" src={loading} alt="Loading" />}
            </Button>
          </Box>
        </>
        {/* //   )
          // : (
          // <Box>
          //   <img src={CheckGreenCircle} style={{ marginBottom: '36px' }} />
          //   <Typography sx={{ fontFamily: 'Abel', fontSize: '36px', fontWeight: '400', color: '#FFFFFF', marginBottom: '10px' }}>
          //     Label added to cart
          //   </Typography>
          //   <Typography sx={{ fontFamily: 'Abel', fontSize: '20px', fontWeight: '400', color: '#ACACAC', marginX: '30px' }}>
          //     Your design has been successfully added to your cart.
          //   </Typography>
          //   <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '36px', marginX: '10px' }}>
          //     <Button
          //       onClick={redirectToCart}
          //       className="selectButton logoSelectButton"
          //       variant="outlined"
          //       sx={{
          //         color: '#FFFFFF',
          //         borderRadius: '10px',
          //         borderColor: '#FFFFFF',
          //         '&:hover': {
          //           color: '#FFFFFF'
          //         },
          //         '&:disabled': {
          //           color: '#FFFFFF',
          //           opacity: 0.4,
          //           borderColor: 'gray',
          //           backgroundColor: 'transparent'
          //         }
          //       }}
          //     >
          //       View Cart
          //     </Button>
          //     <Button
          //       onClick={onCreateAnotherDesign}
          //       sx={{ borderWidth: '1px', borderStyle: 'solid', borderColor: '#FFFFFF', borderRadius: '10px' }}
          //       variant="contained"
          //     >
          //       Create Another
          //     </Button>
          //   </Box>
          // </Box>
          //   )} */}
      </Box>
    </Box>
  );
};

export default ViewCartPopup;
