import React, { useEffect, useState } from "react";
import AdminMenu from "../Components/AdminMenu/AdminMenu";
import { Routes, Route } from "react-router-dom";
import MyDesigns from "./Admin/MyDesigns/MyDesigns";
import { Button, Modal, Box } from "@mui/material";
import close from "../images/close.svg";
import axios from "axios";
import RetrieveDesign from "./Admin/RetrieveDesign/RetrieveDesign";
import RetrieveOrder from "./Admin/RetrieveOrder/RetrieveOrder";

const Admin = ({ activePage, setActivePage, setActiveStep, setData }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [designData, setDesignData] = useState([]);
  const [id, setId] = useState(null);
  const adminStatus = sessionStorage.getItem("adminStatus");

  useEffect(() => {
    getOrders();
  }, []);

  const handleOpenModal = (id) => {
    setIsOpenModal(true);
    setId(id);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
    setId(null);
  };

  function getOrders() {
    const token = localStorage.getItem("token");

    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_API_URL || "https://labelmaker.traceaudioserver.com" + "/api/v1/designs",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        const sortedData = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        setDesignData(sortedData);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const deleteitem = (id) => {
    const config = {
      method: "post",
      url: process.env.REACT_APP_API_URL || "https://labelmaker.traceaudioserver.com" + "/api/v1/removeDesignById/" + id,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response.status === 200) {
          setDesignData(designData.filter((e) => e.Id !== id));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const confirmDelete = (id) => {
    deleteitem(id);
    setIsOpenModal(false);
  };

  return (
    <Box className="admin">
      <AdminMenu activePage={activePage} setActivePage={setActivePage} setActiveStep={setActiveStep} />
      <Box className="adminPage" sx={{ width: "100%" }}>
        <Routes basename="/admin">
          <Route
            exact
            path="/"
            element={
              <MyDesigns
                setActivePage={setActivePage}
                setActiveStep={setActiveStep}
                setData={setData}
                deleteitem={deleteitem}
                designData={designData}
                handleOpenModal={handleOpenModal}
                getOrders={getOrders}
              />
            }
          />
          <Route
            exact
            path="/mydesigns"
            element={
              <MyDesigns
                setActivePage={setActivePage}
                setActiveStep={setActiveStep}
                setData={setData}
                deleteitem={deleteitem}
                designData={designData}
                handleOpenModal={handleOpenModal}
              />
            }
          />
          {+adminStatus === 2 && <Route exact path="/retrieve-design" element={<RetrieveDesign setActiveStep={setActiveStep} />} />}
          {+adminStatus === 2 && <Route exact path="/retrieve-order" element={<RetrieveOrder setActiveStep={setActiveStep} />} />}
        </Routes>
      </Box>
      <Modal open={isOpenModal} onClose={handleCloseModal}>
        <Box
          style={{
            backgroundColor: "rgba(54,54,54, 0.94)",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "300px",
            height: "150px",
            borderRadius: "12px",
            padding: "12px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <p style={{ color: "white" }}>Delete Design</p>
            <img style={{ cursor: "pointer" }} onClick={handleCloseModal} src={close} alt="close-icon" />
          </Box>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Button variant="outlined" onClick={() => setIsOpenModal(false)}>
              Cancel
            </Button>
            <Button variant="contained" onClick={() => confirmDelete(id)}>
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Admin;
